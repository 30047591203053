@font-face {
  font-family: 'proxima_nova_regular';
  src: url('./fonts/proximanova-regular.eot');
  src: url('./fonts/proximanova-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/proximanova-regular.woff2') format('woff2'), url('./fonts/proximanova-regular.woff') format('woff'), url('./fonts/proximanova-regular.ttf') format('truetype'), url('./fonts/proximanova-regular.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_bold';
  src: url('./fonts/proximanova-bold.eot');
  src: url('./fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/proximanova-bold.woff2') format('woff2'),
    url('./fonts/proximanova-bold.woff') format('woff'),
    url('./fonts/proximanova-bold.ttf') format('truetype'),
    url('./fonts/proximanova-bold.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaregular_italic';
  src: url('./fonts/proximanova-regitalic.eot');
  src: url('./fonts/proximanova-regitalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/proximanova-regitalic.woff2') format('woff2'),
    url('./fonts/proximanova-regitalic.woff') format('woff'),
    url('./fonts/proximanova-regitalic.ttf') format('truetype'),
    url('./fonts/proximanova-regitalic.svg#proxima_novaregular_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'proxima_nova_regular', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #F7F7F9;*/
  background: #373E44;
}

.font-bold {
  font-family: 'proxima_nova_bold', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.font-italic {
  font-family: 'proxima_novaregular_italic', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
primary: FCB131 //orange
secondary: 373E44 // dark 
tertiary> 3F515B //	light
background: F7F7F9	//whitish
*/

p {
  font-size: 15px;
}

.webgl-content .logo,
.progress {
  animation: .5s showProgress both;
}

@keyframes showProgress {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.App {
  display: grid;
  grid-template-areas:
    "head head"
    "sidebar configurator";
  grid-auto-columns: 300px 1fr;
  height: 100vh;
  grid-auto-rows: 70px 1fr;
}

.App.no-sidebar {
  grid-auto-columns: 70px 1fr;
}

.App.no-sidebar .panel h2 span:nth-child(2) {
  display: none;
}

.App.no-sidebar .panel .panel__content {
  display: none;
}

.App.no-sidebar #__ReactUnityWebGL_1__ {
  width: calc(100vw - 70px) !important;
}

.toggle-sidebar {
  position: absolute;
  right: 0;
  top: 19px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.header {
  grid-area: head;
  background: white;
  animation: .5s showHeader both;
  position: relative;
  z-index: 5;
  animation-delay: .1s;
}

.header .logo {
  float: left;
}



.header-price {
  float: right;
}

.header-price>div {
  display: flex;
  align-items: center;
}

.sidebar {
  grid-area: sidebar;
  background: #373E44;
  color: white;
  overflow: hidden;
  position: relative;
  /*animation: .5s showSidebar both;
  animation-delay: .3s;*/
}


@media(max-width: 870px) {
  .template-popup {
    display: grid;
    overflow-y: scroll;
    height: 80vh;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  display: none;
  /* 2 */
}

::-webkit-scrollbar-thumb {
  /* 5 */
  background: #B7BBBE;
}

html,
body,
div {
  scrollbar-color: #B7BBBE white;
  scrollbar-width: thin;
}

#root {
  overflow: hidden;
}



@media (hover: none) {
  #root {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  @supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    #root {
      position: absolute;
    }
  }

  @supports (-webkit-touch-callout: none) {
    @media(max-device-width: 768px) and (orientation: portrait) {
      .App:not(.mobile-active) #__ReactUnityWebGL_1__ canvas {
        height: 40vh !important;
      }
    }
  }

  .configurator .size-component {
    top: 45px;
  }

  .mobile-active .size-component {
    top: 15px;
  }

  .pan-component {
    position: fixed !important;
  }

  .mobile-toggled:not(.mobile-active) .pan-component {
    z-index: 0;
  }

  .preview {
    display: block !important;
  }

  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=number],
  select,
  input[type=checkbox] {
    appearance: none;
    border-radius: 0 !important;
  }
}

/*mobile and touch enabled*/
@media(max-width: 769px) and (hover: none) {
  .preview img {
    width: 100%;
  }

  .configurations button span:last-child {
    width: auto !important;
  }

  .configurations {
    margin-top: 20px !important;
  }

  .templates {
    margin-top: 0px !important;
  }

  .landing-page {
    justify-content: flex-start !important;
  }

  .landing-page img {
    width: 20vh !important;
    margin-top: 20px;
  }

  .stepper-holder input {
    font-size: 16px;
    line-height: 16px;
  }

  .App.mobile-toggled .large.tabbed {
    height: calc(100vh - 270px) !important;
  }

  .large.preview {
    height: calc(100vh - 240px) !important;
  }

  .pan-component {
    display: none !important;
  }

  .App.mobile-toggled .panel.active .panel__content>*:last-child {
    padding-bottom: 120px !important;
  }

  .App.mobile-toggled .panel.active .panel__content {
    min-height: calc(100vh - 156px) !important;
    max-height: calc(100vh - 156px) !important;
  }

  .pan-component {
    display: none !important;
  }

  .panel.active .panel__content {
    padding-bottom: 100px !important;
  }

  .panel.active .panel__content>div:last-child {
    padding-bottom: 100px !important;
  }

  /*.panel.active .panel__content .tabs{
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  .panel.active .panel__content .tabs + *{
    margin-top: 60px;  
  }*/

  .large.tabbed>div:last-child {
    padding-bottom: 100px !important;
  }

  .hide-content {
    display: none;
  }
}

@keyframes showHeader {
  0% {
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes showSidebar {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}



.configurator {
  grid-area: configurator;
  background: #F7F7F9;
  background: #373E44;
}

input[type="text"],
input[type="email"] {
  padding: 10px 10px;
  border: 1px solid #84888C;
}

select,
button,
a,
label,
input[type=text],
input[type="email"],
input[type="number"] {
  font-family: 'proxima_nova_regular', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

select option {
  line-height: 20px;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.template-popup {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media only screen and (max-width: 769px) {
  .template-popup {
    flex-direction: column;
  }

  .template-popup::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 18%;
    background: transparent;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
  }
}


.template-popup>div {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: all .5s linear;
  border: 2px solid transparent;
  cursor: pointer;
}

.template-popup>div img {
  object-fit: contain;
}

.template-popup>div:hover {
  border: 2px solid rgba(0, 0, 0, .3);
}

.template-width {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-width svg {
  transform: rotate(90deg);
  width: 20px;
  margin-right: 5px;
}

.mobile-toggle {
  display: none;
}

.in-active+.header .sidebar div .mobile-toggle {
  /*display: flex;*/
}

.mobile-toggle {
  top: calc(50vh - 3px) !important;
  border: none !important;
  position: fixed !important;
  left: 0px !important;
  z-index: 3 !important;
  background: #9ea0a2 !important;
  width: 100%;
  height: 30px;
  display: none;
  animation: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, .5) !important;
}

.mobile-toggle button {
  top: -4px !important;
  left: calc(50% - 12px) !important;
  transform: rotate(-90deg) !important;
}

/* mobile size */
@media(max-width: 768px) {
  .App {
    grid-template-areas:
      "head"
      "sidebar"
      "configurator";
    grid-auto-columns: 1fr;

    /*grid-auto-rows: 70px 100vh 100vh;*/
    /* old full height */

    grid-auto-rows: 70px calc(50vh - 73px) 50vh;
  }

  .App.mobile-active {
    grid-auto-rows: 70px 60px calc(100vh - 130px);
  }

  .App.mobile-toggled:not(.mobile-active) {
    grid-auto-rows: 70px 100vh 0vh;
  }

  .App.mobile-active .configurator>div:nth-child(1),
  .App.mobile-active .configurator>div:nth-child(2) {
    position: fixed;
  }

  .App.mobile-active .tabbed {
    display: none;
  }

  @media(max-width: 768px) {

    .product-item h4,
    .product-item {
      word-break: break-word;
    }
  }

  .notification {
    top: 50px !important;
  }

  .mobile-active .notification {
    top: 150px !important;
  }

  .large.tabbed {
    height: calc(50vh - 171px);
  }

  .App.mobile-toggled .large.tabbed {
    height: calc(100vh - 200px);
  }

  #unityContainer {
    width: 100vw !important;
    height: 100vh !important;
  }

  .webgl-content {
    position: relative !important;
  }

  .App.mobile-active #data {
    position: fixed !important;
    top: 142px;
  }

  #__ReactUnityWebGL_1__ {
    width: 100vw !important;
    height: calc(100vh - 330px) !important;
    /*height: calc(100vh - 130px) !important;*/
  }

  .mobile-active #__ReactUnityWebGL_1__ {
    height: calc(100vh - 130px) !important;
  }

  .App:not(.mobile-active) .in-active+.header+.sidebar+div+.mobile-toggle,
  .App:not(.mobile-active) .in-active+.header+.sidebar+div+div+div+.mobile-toggle {
    display: flex !important;
  }

  .App.mobile-toggled .mobile-toggle {
    /*display: flex !important;*/
    top: auto !important;
    bottom: 0;
  }

  .large.preview {
    height: calc(100vh - 170px);
  }
}


@media(max-width: 769px) and (hover:auto) {
  .panel.active .panel__content {
    min-height: calc(50vh - 130px) !important;
    max-height: calc(50vh - 130px) !important;
  }

  .App.mobile-toggled .panel.active .panel__content {
    min-height: calc(100vh - 156px) !important;
    max-height: calc(100vh - 156px) !important;
  }

}

/* ipad vw/vh fix */
@media (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) {
  .App {
    grid-auto-rows: 70px calc(100vh - 100px);
    height: calc(100vh - 32px);
  }

  header {
    /*background: blue !important;*/
  }

  .panel.active .panel__content {
    /*min-height: calc(((100vh - 285px) - 70px) - 90px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 90px) !important;*/
  }

  /*.panel{
      max-height: calc((100% - 228px) - 60px) !important;
  }*/

  .panel h2 {
    font-size: 14px !important;
  }

  .checkout--mobile {
    display: none;
  }

  .header-menu h4 {
    font-size: 13px;
  }

  .header-menu h4 span {
    margin-top: 7px !important;
  }

  .large>div:last-child {
    padding-bottom: 80px !important;
  }
}


/*  ipad 12"  */
@media(max-height: 1060px) and (max-device-width : 1024px) and (min-device-width: 768px) and (orientation: landscape) {
  header {
    /* background-color: turquoise !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 100px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 70px) - 90px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 90px) !important;
  }
}

/*  ipad 12" w tabs */
@media(max-height: 950px) and (max-device-width : 1024px) and (min-device-width: 768px) and (orientation: landscape) {
  header {
    /* background-color: purple !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 130px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 70px) - 120px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 120px) !important;
  }
}

/* pro 11 w */
@media(max-height: 760px) and (max-device-width : 1024px) and (min-device-width: 768px) and (orientation: landscape) {
  header {
    /*background-color: teal !important;*/
  }

  .large>div:last-child {
    padding-bottom: 80px !important;
  }

  .panel.active {
    min-height: calc((100% - 228px) - 100px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 70px) - 90px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 90px) !important;
  }
}

/* pro 11 / air 2019 . tabs */
@media(max-height: 740px) and (max-device-width : 1024px) and (min-device-width: 768px) and (orientation: landscape) {
  header {
    /*background-color: orange !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 100px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 70px) - 120px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 120px) !important;
  }
}

/* air 2019 */
@media(max-height: 766px) and (min-height: 760px) and (max-device-width : 1024px) and (orientation: landscape) {
  header {
    /*background-color: greenyellow !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 170px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 40px) - 120px) !important;
    max-height: calc(((100vh - 285px) - 40px) - 120px) !important;
  }
}

/* air with tabs */
@media(max-height: 710px) and (max-device-width : 1024px) and (orientation: landscape) {
  header {
    /*background-color: red !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 170px) !important;
  }

  .panel.active .panel__content {
    min-height: calc((100vh - 285px) - 160px) !important;
    max-height: calc((100vh - 285px) - 160px) !important;
  }
}

/* air with tabs */
@media(max-height: 670px) and (max-device-width : 1024px) and (orientation: landscape) {
  header {
    /*background-color: green !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 170px) !important;
  }

  .panel.active .panel__content {
    min-height: calc((100vh - 285px) - 193px) !important;
    max-height: calc((100vh - 285px) - 193px) !important;
  }
}


/* 9.7 , 11, 12 portrait */
@media(max-device-width : 1024px) and (min-device-width: 768px) and (orientation: portrait) {
  header {
    /*background-color: pink !important;*/
  }

  .panel.active {
    min-height: calc((100% - 228px) - 100px) !important;
  }

  .panel.active .panel__content {
    min-height: calc(((100vh - 285px) - 70px) - 120px) !important;
    max-height: calc(((100vh - 285px) - 70px) - 120px) !important;
  }
}

@media(max-width: 769px) and (orientation: portrait) {
  header .logo img {
    max-height: 40px;
    padding: 0 !important;
  }
}