.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.notification-saved{
  position: fixed;
  width: 200px;
  height: 100px;
  background:rgba(255,255,255,.9);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  left: 50%;
  margin-left: -100px;
  top: -200px;
  transition: all .5s ease-in-out;
  padding: 10px 30px;
  border-radius: 2px;
  box-sizing: border-box;
  /*transform: translateX(-50%) translateY(-100px);*/
}

.notification-saved.show{
  top: 80px;
}
.notification-saved.hide{
  top: -100px;
}

@media(max-width: 769px){
  .App:not(.mobile-active) .mobile-toggle{
    display: block;
  }
}
