.right img{
    float: right;
}

.config img{
    float: center;
}

.customerinfo p{
    float: left;
    font-family: 'proxima_nova_bold';

}

.header3 h3{
    float: center;
    font-family: 'proxima_nova_bold';
}

